
import { defineComponent } from 'vue';
import PlainContent from '../types/PlainContent';
import HowCanHelpContent from '@/content/HowCanHelpContent';
import ShapeMask from '@/components/atoms/ShapeMask.vue';

export default defineComponent({
  name: 'HowCanWeHelpComp',
  components: {
    ShapeMask,
  },
  setup() {
    const content: PlainContent = HowCanHelpContent;

    return {
      content,
    };
  },
});
