/* eslint-disable global-require */
/* eslint-disable max-len */
import PlainContent from '@/types/PlainContent';

const HowCanHelpContent: PlainContent = {
  title: 'How can we help?',
  body: `<p>
          If you’ve already got your eye on a potential job family, we can jump straight in and
          point you in the right direction.
        </p>
        <h3>Do you already know which job family you'd like to work in?</h3>`,
};

export default HowCanHelpContent;
