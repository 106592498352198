
import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import HowCanWeHelpComp from '@/components/HowCanWeHelpComp.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'HowCanWeHelp',
  components: {
    Header,
    HowCanWeHelpComp,
    Footer,
  },
});
